import React, { Component } from 'react'
import Iframe from 'react-iframe'
// import { styles } from '../../utils'
import styled from 'styled-components'

class Map extends Component {
  render() {
    return (
      <MapWrapper>
        <Iframe
          url='https://www.google.com/maps/d/u/0/embed?mid=17j-FPDgFpvVtdw1nyEPZ7WJydAJuYoyv'
          width='100%'
          height='430px'
          frameborder='0'
          display='initial'
          position='relative'
          allowfullscreen
        />
      </MapWrapper>
    )
  }
}
export default Map

const MapWrapper = styled.div`
  width: 100%;
  height: 430px;
  margin-bottom: 0;
  @media (max-width: 768px) {
    height: 200px !important;
  }
`

import React, { Component } from 'react'
import styled from 'styled-components'
import { styles } from '../../utils'
import logo from '../../images/wms.png'
// import { Link } from 'gatsby'

export default class ContactInfo extends Component {
  render() {
    return (
      <ContactWrapper>
        <div className='container'>
          <center>
            <h1>Contact Info</h1>
          </center>
          <br />
          <div className='row'>
            <div className='col-md-6'>
              <center>
                <img src={logo} alt='WMS logo' />
              </center>
            </div>

            <div className='col-md-6'>
              <center>
                <h3>Scott Walley</h3>
                <ul>
                  <li>251-209-1992</li>
                  <li>
                    Email:{' '}
                    <a href='mailto:sawalley70@gmail.com'>
                      scott@walleymarinesurveying.com
                    </a>
                  </li>
                </ul>
              </center>
            </div>
          </div>
        </div>
      </ContactWrapper>
    )
  }
}

const ContactWrapper = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 1rem;
  h1 {
    color: ${styles.colors.Navy};
    font-size: 28px;
    text-transform: uppercase;
    margin-top: 2rem;
  }
  h3 {
    font-weight: 400;
  }
  img {
    max-width: 150px;
  }
  ul {
    text-align: center;
    list-style-type: none;
    margin-left: 0;
  }
`

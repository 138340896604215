import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../components/layout'
// import Image from '../components/image'
import SEO from '../components/seo'
// import { FaBeer } from 'react-icons/fa'
// import img from '../images/contactimg.jpg'
// import { PageHeader, Banner } from '../utils/'
import ContactInfo from '../components/ContactComponents/ContactInfo'
import Map from '../components/ContactComponents/Map'

const ContactPage = () => (
  <Layout>
    <SEO
      title='Contact S.A. Walley Marine Surveying'
      keywords={[
        `Marine Surveying`,
        `Gulf Coast`,
        `Walley`,
        `Pensacola`,
        `Florida`,
        `Orange Beach`,
        `Gulf Shores`,
        `Alabama`,
        `Perdido Key`,
        `Northwest Florida`,
        `Mississippi`,
        `Louisiana`,
        `Tennessee`,
        `Constulting`,
        `Delivery`,
        `Vessel`,
        `Boat`,
        `Evaluation`,
        `Insurance Claim`,
        `Valuation`
      ]}
    />

    <ContactInfo />

    <Map />
  </Layout>
)

export default ContactPage
